import {
	GET_INFLUENCER,
	API_SERVICE,
	GET_COUPON,
	UPDATE_COUPON_STATUS,
	WEB_API,
	WEB_API_STAGE,
    
} from "./Type";

const getInfluencer = (dispatch) => {
	fetch(`${API_SERVICE}/get-influecer`)
		.then((response) => response.json())
		.then((result) => {
			return dispatch({
				type: GET_INFLUENCER,
				payload: result,
			});
		})
		.catch((error) => console.log("error", error));
};

const getCoupons = () => (dispatch) => {
	fetch(`${API_SERVICE}/get-coupons`)
		.then((response) => response.json())
		.then((result) => {
			return dispatch({
				type: GET_COUPON,
				payload: result,
			});
		})
		.catch((error) => console.log("error", error));
};
// const getCoupon = () => (dispatch) => {
// 	fetch(`${API_SERVICE}/get_coupon`)
// 		.then((response) => response.json())
// 		.then((result) => {
// 			return dispatch({
// 				type: GET_COUPON,
// 				payload: result,
// 			});
// 		})
// 		.catch((error) => console.log("error", error));
// };
// const getSingleCoupon = (dispatch,data) => {
//  fetch(`${API_SERVICE}/get_coupon?code=${data}`)
//     .then((response) => response.json())
//     .then((result) => {
//         return dispatch({
//             type: GET_SINGLE_COUPON,
//             payload: result,
//         });
//     })
//     .catch((error) => console.log("error", error));
// };
const updateCouponStatus = (data) => (dispatch) => {
	fetch(`${WEB_API}/coupon/updateactivation`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	})
		.then(function (response) {
			return response.json();
		})
		.then(function (dataRes) {
			return dispatch({
				type: UPDATE_COUPON_STATUS,
				payload: dataRes,
			});
		})
		.catch((error) => console.log("error", JSON.stringify(error)));
};

export { getInfluencer, getCoupons, updateCouponStatus };
