import { API_SERVICE, CREATE_COUPON } from './Type'

const createCoupon = (data) => (dispatch) => {

  return new Promise( (resolve,rejection) => {
    setTimeout(() => resolve("done"), 1000);
  });
  // fetch(`${API_SERVICE}/create-coupon-code`, {
  //   method: 'post',
  //   body: JSON.stringify(data)
  // }).then(function(response) {
  //   return response.json();
  // }).then(function(dataRes) {
  //   console.log("dataRes", dataRes);
  //   return dispatch({
  //     type: CREATE_COUPON,
  //     payload: dataRes
  //   });
  // }).catch((error) => console.log("error", JSON.stringify(error)));


};

export { createCoupon }