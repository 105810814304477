import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Switch from "@material-ui/core/Switch";

import Loader from "../components/Loader";
import {Link} from "react-router-dom";

import { getCoupons, updateCouponStatus } from "../_actions/GetCouponsAction";
import { WEB_API_STAGE } from "../_actions/Type";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	table: {
		minWidth: 650,
	},
}));

function Coupons(props) {
	const { getCoupon } = props;
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [state, setState] = React.useState();

	useEffect(() => {
		props.getCoupons();

		setLoading(false);
	}, [loading]);

	const updateCouponStatus = (couponCode, status, value) => {
		setLoading(true);

		props.updateCouponStatus({
			codeValue: value,
			couponCode: couponCode,
			couponStatus: status ? 0 : 1,
			email: JSON.parse(localStorage.getItem("user")).email,
		});
		setTimeout(() => {
			setLoading(false);
			window.location.reload();
		}, 1500);
	};

	if (loading) {
		return <Loader />;
	} else {
		let getCoupons =
			props.getCoupon && props.getCoupon.getCoupon && props.getCoupon.getCoupon.length > 0
				? props.getCoupon.getCoupon
				: [];
        console.log("HELLO",getCoupons)

		return (
			<div className={classes.root}>
				<Box mt={5} style={{ marginLeft: "20%" }}>
					<Container>
						<Grid container xs={8} direction="row" justify="center" alignItems="center">
							<TableContainer component={Paper} mt={5}>
								<Table
									className={classes.table}
									aria-label="simple table"
									align="center"
								>
									<TableHead variant="head">
										<TableRow>
											<TableCell align="center">
												<strong>Coupon Code</strong>
											</TableCell>

											<TableCell>
												<strong>Update Coupon</strong>
											</TableCell>
                                            <TableCell>
												<strong>Edit Coupon</strong>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{getCoupons.map((item, index) => (
											<TableRow key={index}>
												<TableCell
													align="center"
													component="th"
													scope="row"
												>
													{item.coupon_code}
												</TableCell>
												<TableCell>
													<FormControlLabel
														control={
															<Switch
																checked={
																	item.is_active === 1
																		? true
																		: false
																}
																onClick={(e) =>
																	updateCouponStatus(
																		item.coupon_code,
																		item.is_active,
																		item.code_value
																	)
																}
																name="checkedB"
																color="primary"
															/>
														}
														label={
															item.is_active === 1
																? "Active"
																: "InActive"
														}
													/>
												</TableCell>
                                                <TableCell
													align="center"
													component="th"
													scope="row"
												>
                                                    {/* <Link to={`/edit-coupon/${item.coupon_code}`}>Edit</Link> */}
                                                    <Link to={`/edit-coupon-prod/${item.coupon_code}`}>Edit</Link>
													{/* {item.coupon_code} */}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Container>
				</Box>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getCoupons: () => {
			dispatch(getCoupons());
		},
		updateCouponStatus: (data) => {
			dispatch(updateCouponStatus(data));
		},
	};
};

const mapStateToProps = (state) => ({
	getCoupon: state.getCoupon,
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
