import React from "react";
import { API_SERVICE, WEB_API } from "../_actions/Type";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Loader from "../components/Loader";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { firebase } from "../firebase";
import { influencerValidation } from "../_utils/form_validation";
import { getCoupons, updateCouponStatus } from "../_actions/GetCouponsAction";

const db = firebase.firestore();
function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const styles = (theme) => ({
	fab: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	["MuiSvgIcon-root"]: {
		fontSize: "1rem",
	},
});

class Influencer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isloading: true,
			getInfluencer: {},
			isEdit: true,
			updateObj: {
				name: "",
				email: "",
				paypalId: "",
				couponCode: "",
			},
			error: {
				name: "",
				email: "",
				paypalId: "",
				couponCode: "",
			},
		};
	}

	componentDidMount() {
		this.props.getCoupons();
		fetch(`${WEB_API}/getinfluencercoupons`)
			.then((response) => response.json())
			.then((result) => {
				console.log("result", result);
				this.setState({ isloading: false, getInfluencer: result });
			})
			.catch((error) => console.log("error", error));
	}

	handleChange = (e) => {
		let { updateObj } = this.state;
		let name = e.target.name;
		let event_value = e.target.value;
		let inputObj = { ...updateObj, [name]: event_value };

		this.setState({ updateObj: inputObj });
	};

	submitHandler = (e, email) => {
		if (document.getElementById("combo-box-demo").value.length === 0) {
			alert("Please select coupon code");
		} else {
			let inputObj = {
				email: email,
				updateObj: {
					name: document.getElementById("name").value,
					email: document.getElementById("email").value,
					paypalId: document.getElementById("paypal_id").value,
					couponCode: document.getElementById("combo-box-demo").value,
				},
			};

			console.log(inputObj.updateObj);
			let inputError = influencerValidation(inputObj.updateObj);
			const { errors } = inputError;

			console.log(errors);
			if (!inputError.formIsValid) {
				this.setState({ error: { ...errors } });
				e.stopPropagation();
				return;
			}

			this.setState({ isloading: true });
			fetch(`${API_SERVICE}/update-influencer`, {
				method: "post",
				body: JSON.stringify(inputObj),
			})
				.then(function (response) {
					return response.json();
				})
				.then(function (dataRes) {
					this.setState({ isloading: false });
					if (dataRes.errorMsg) {
						alert(dataRes.err.sqlMessage);
						window.location.reload();
					} else {
						alert("Create Coupon Successfuly!");
						window.location.reload();
					}
				})
				.catch((error) => {
					console.log("error", JSON.stringify(error));
					alert("Update Successfuly !");
					window.location.reload();
					this.setState({ isloading: false });
				});
		}
	};

	onChangeRedeemPending = (e, index) => {
		let value = parseInt(e.target.value) === 1 ? true : false;
		console.log(e.target.value, this.state.getInfluencer[index].email);
		db.collection("influencer-coupons")
			.where("email", "==", this.state.getInfluencer[index].email)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					db.collection("influencer-coupons")
						.doc(doc.id)
						.update({ is_redeem_pending: value });
				});
			});
	};

	render() {
		const { classes } = this.props;
		const { isloading, getInfluencer, isEdit, error } = this.state;
		console.log("getInfluencer", getInfluencer);
		let getCoupons =
			this.props.getCoupon &&
			this.props.getCoupon.getCoupon &&
			this.props.getCoupon.getCoupon.length > 0
				? this.props.getCoupon.getCoupon
				: [];
		if (isloading) {
			return <Loader />;
		} else {
			return (
				<div className="mt-5">
					<div>
						<TableContainer component={Paper}>
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Name</TableCell>
										<TableCell>Email</TableCell>
										<TableCell>Paypal Id</TableCell>
										<TableCell>Coupon Code</TableCell>
										<TableCell>Commission Percentage</TableCell>
										<TableCell>Total Order</TableCell>
										<TableCell>Total Discount</TableCell>
										<TableCell>Total Commission</TableCell>
										<TableCell>Redeem Pending</TableCell>
										<TableCell>Edit</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{getInfluencer.map((row, index) => (
										<TableRow key={index}>
											<TableCell>{row.created_at}</TableCell>
											<TableCell component="th" scope="row">
												{isEdit === index ? (
													<TextField
														error={error.name === "" ? false : true}
														defaultValue={row.name}
														variant="outlined"
														margin="normal"
														required
														fullWidth
														id="name"
														label="name"
														name="name"
														autoFocus
														helperText={error.name}
														onChange={(e) => this.handleChange(e)}
													/>
												) : (
													row.name
												)}
											</TableCell>
											<TableCell>
												{isEdit === index ? (
													<TextField
														error={error.email === "" ? false : true}
														defaultValue={row.email}
														variant="outlined"
														margin="normal"
														required
														fullWidth
														id="email"
														label="email"
														name="email"
														helperText={error.email}
														autoFocus
														onChange={(e) => this.handleChange(e)}
													/>
												) : (
													row.email
												)}
											</TableCell>
											<TableCell>
												{isEdit === index ? (
													<TextField
														error={error.paypalId === "" ? false : true}
														defaultValue={row.paypal_id}
														variant="outlined"
														margin="normal"
														required
														id="paypal_id"
														label="paypal_id"
														name="paypal_id"
														helperText={error.paypalId}
														autoFocus
														onChange={(e) => this.handleChange(e)}
													/>
												) : (
													row.paypal_id
												)}
											</TableCell>
											<TableCell>
												{isEdit === index ? (
													<Autocomplete
														id="combo-box-demo"
														options={getCoupons}
														getOptionLabel={(option) =>
															option.coupon_code
														}
														style={{ width: 150 }}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Coupon Code"
																variant="outlined"
															/>
														)}
													/>
												) : (
													row.coupon_code
												)}
											</TableCell>
											<TableCell>
												{row.commission_percentage.length === 3
													? row.commission_percentage
													: `${row.commission_percentage}%`}
											</TableCell>
											<TableCell>
												{/* <AttachMoneyIcon style={{ fontSize: "1rem" }} /> */}
												{/* {row.currency === "USD" ? (
													<AttachMoneyIcon style={{ fontSize: "1rem" }} />
												) : row.currency === "INR" ? (
													"\u20B9"
												) : (
													""
												)}{" "} */}
												${parseFloat(row.total_order)}
											</TableCell>
											<TableCell>
												{row.total_discount || 0}
												{row.discountType === "percentage" ? "%" : ""}
											</TableCell>
											<TableCell>
												{/* {row.currency === "USD" ? (
													<AttachMoneyIcon style={{ fontSize: "1rem" }} />
												) : row.currency === "INR" ? (
													"\u20B9"
												) : (
													""
												)}{" "} */}
												$
												{(
													(parseFloat(row.total_order) *
														parseInt(row.commission_percentage)) /
													100
												).toFixed(2)}
											</TableCell>
											<TableCell>
												<select
													onChange={(e) =>
														this.onChangeRedeemPending(e, index)
													}
												>
													<option value="0">False</option>
													<option value="1">True</option>
												</select>
											</TableCell>
											<TableCell>
												{isEdit === index ? (
													<div className="d-flex">
														<DoneIcon
															onClick={(e) => {
																this.submitHandler(e, row.email);
															}}
														/>
														<CloseIcon
															onClick={(e) => {
																this.setState({ isEdit: -1 });
															}}
														/>
														{/* <Button variant="contained" color="primary" onClick={(e) => { this.submitHandler(e, row.email) }}>Update</Button> */}
													</div>
												) : (
													<EditIcon
														onClick={(e) => {
															this.setState({ isEdit: index });
														}}
													/>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				</div>
			);
		}
	}
}

const styledComponent = withStyles(styles)(Influencer);

const mapDispatchToprops = (dispatch) => {
	return {
		getCoupons: () => {
			dispatch(getCoupons());
		},
	};
};

const mapStateToprops = (state) => ({
	getCoupon: state.getCoupon,
});

export default connect(mapStateToprops, mapDispatchToprops)(styledComponent);
