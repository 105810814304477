import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

// import "./Login.css";
import { withRouter } from "react-router-dom";
import { firebase } from "../firebase";

const users = ["test3@test.com"];
function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://material-ui.com/">
				Playshifu
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

function Login(props) {
	const classes = useStyles();
	var [isLogin, setIsLogin] = useState(true);
	var [email, setEmail] = useState("");
	var [password, setPassword] = useState("");
	var [passwordConfirm, setPasswordConfirm] = useState("");
	var [open, setOpen] = useState(false);
	useEffect(() => {
		firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				console.log("USER", user.email);
				localStorage.setItem("user", JSON.stringify(user));
				// props.history.push("/");
			}
		});
	}, []);
	function emailInput(e) {
		e.preventDefault();
		setEmail(e.target.value);
	}

	function passwordInput(e) {
		e.preventDefault();
		setPassword(e.target.value);
	}

	function signInClicked(e) {
		e.preventDefault();
		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then((res) => {
				console.log("RESPONSE USER SIGNED IN", res);
				localStorage.setItem("user", JSON.stringify(res));
				props.history.push("/");
			})
			.catch(function (error) {
				var errorCode = error.code;
				var errorMessage = error.message;
				alert(errorMessage);
			});
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Login
				</Typography>

				<form className={classes.form} noValidate>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						value={email}
						onChange={(e) => emailInput(e)}
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						value={password}
						onChange={(e) => passwordInput(e)}
						autoComplete="current-password"
					/>
					{/* <FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label="Remember me"
						/> */}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={(e) => signInClicked(e)}
					>
						Login
					</Button>
				</form>
			</div>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	);
}
export default withRouter(Login);
