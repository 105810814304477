import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import RootReducer from '../_reducers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const loggerMiddleware = createLogger();

const middleware = [ReduxThunk, loggerMiddleware];

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, RootReducer);

let store = createStore( 
  persistedReducer , 
  compose(
    applyMiddleware(...middleware),
  ));

let persistor = persistStore(store)

export  {store, persistor};