import { combineReducers } from "redux";
import GetCouponsReducer from "./GetCouponsReducer";
import createCouponRes from "./CreateCouponReducer";
import authReducer from "./Auth";

export default combineReducers({
	getCoupon: GetCouponsReducer,
	createCouponRes: createCouponRes,
	auth: authReducer,
});
