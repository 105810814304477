
import { GET_COUPON, UPDATE_COUPON_STATUS, GET_INFLUENCER } from '../_actions/Type';

const initialState = { loading: true };
function getCouponReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INFLUENCER : 
    return {
      ...state,
      getInfluencer: action.payload
    }
    case GET_COUPON:
      return {
        ...state,
        loading: false,
        getCoupon: action.payload
      };
    case UPDATE_COUPON_STATUS:
      return {
        ...state,
        loading: false,
        updateCoupon: action.payload
      };
    default:
      return state;
  }
}

export default getCouponReducer;