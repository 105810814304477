import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// import CouponCreations from "./pages/CouponCreations";
import CouponCreations from "./pages/CouponCreationsNew";
import CouponEditStage from "./pages/CouponEditStage";
import CouponEditProd from "./pages/CouponEditProd";
import Coupons from "./pages/Coupons";
import Test from "./pages/Home";
import Signup from "./pages/Signup";
import Influencer from "./pages/Influencer";

import Header from "./components/Header";
import AuthRoute from "./components/AuthRoute";
import Login from "./pages/Login";

class App extends Component {
	render() {
		const isAuth = localStorage.getItem("user");
		return (
			<div>
				<BrowserRouter>
					<Switch>
						<Route path="/login" component={Login} />
						<AuthRoute exact path="/">
							<Header />
							<CouponCreations />
						</AuthRoute>

						<AuthRoute path="/coupons">
							<Header />
							<Coupons />
						</AuthRoute>
                        <AuthRoute path="/edit-coupon-stage/:coupon">
							<Header />
							<CouponEditStage />
						</AuthRoute>
                        <AuthRoute path="/edit-coupon-prod/:coupon">
							<Header />
							<CouponEditProd />
						</AuthRoute>
						<AuthRoute path="/influencer">
							<Header />
							<Influencer />
						</AuthRoute>
						<AuthRoute path="/add-influencer">
							<Header />
							<Signup />
						</AuthRoute>

						{/* <Route exact path="/" component={CouponCreations} />
						<Route path="/add-influencer" component={Signup} />
						<Route path="/coupons" component={Coupons} />
						<Route path="/influencer" component={Influencer} /> */}
					</Switch>
				</BrowserRouter>
			</div>
		);
	}
}

export default App;
