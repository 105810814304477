import React from "react";
import { Redirect, Route } from "react-router-dom";

const AuthRoute = (props) => {
	console.log("props", props);
	const user = localStorage.getItem("user");
	if (user) return <Route {...props} />;
	else return <Redirect to="/login" />;
};

export default AuthRoute;
