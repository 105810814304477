import { LOGIN } from "../_actions/Type";

const initialState = { isAuthUser: !!localStorage.getItem("user") };
function authReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				isAuthUser: action.payload,
			};
		default:
			return state;
	}
}

export default authReducer;
