import React from "react";
import { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { API_SERVICE } from "../_actions/Type";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { signup } from "../_actions/SignupAction";
import Loader from "../components/Loader";
import { getCoupons } from "../_actions/GetCouponsAction";
import { handleAddInfluencerValidation } from "../_utils/form_validation";
import { Input } from "@material-ui/core";

var differenceBy = require("lodash.differenceby");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function AddInfluencer(props) {
  const { getCoupon } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState();
  const [couponCode, setCouponCode] = useState();
  const [loading, setLoading] = useState();
  const [allInfluencer, setAllInfluencer] = useState();
  const countryOptions = ["US", "UK", "CA", "IN"];
  const [influencerCountry, setInfluencerCountry] = useState();
  useEffect(() => {
    props.getCoupons();
    fetch(`${API_SERVICE}/get-influencer`)
      .then((response) => response.json())
      .then((result) => {
        setAllInfluencer(result);
        //  this.setState({isloading: false, getInfluencer: result})
      })
      .catch((error) => console.log("error", error));
    setIsLoading(false);
  }, [isLoading]);

  const [formData, setFormData] = useState({
    name: "",
    paypal_id: "",
    email: "",
    commission: "",
    total_referral_purchases: 0,
    total_bonus: 0,
    total_uncredited: 0,
  });
  const [error, setError] = useState({
    name: "",
    paypal_id: "",
    email: "",
    commission: "",
  });

  const handleChange = (e) => {
    setError({
      name: "",
      paypal_id: "",
      email: "",
      coupon_code: "",
      commission: "",
    });

    let name = e.target.name;
    let event_value = e.target.value;
    setFormData((prevState) => ({ ...prevState, [name]: event_value }));
  };

  const submitHandler = (e) => {
    const couponCode = document.getElementById("combo-box-demo").value;
    const country = document.getElementById("country-box-selection").value;
    let inputObj = { ...formData, coupon_code: couponCode, country: country };
    inputObj.email = inputObj.email.toLowerCase()
    let inputError = handleAddInfluencerValidation(inputObj);
    const { errors } = inputError;
    console.log(errors);
    if (!inputError.formIsValid) {
      setError({ ...errors });
      e.stopPropagation();
      return;
    }
    setLoading(true);

    fetch(`${API_SERVICE}/inluencer-signup`, {
      method: "post",
      body: JSON.stringify(inputObj),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (dataRes) {
        setLoading(false);
        if (dataRes.errMsg) {
          alert("influencer already exists");
        } else {
          alert("Add influencer Successfuly!");
        }
      })
      .catch((error) => {
        setLoading(false);
        alert("Server Error");
        console.log("error", JSON.stringify(error));
      });
  };

  if (loading) {
    return <Loader />;
  } else {
    let getCouponsRes =
      props.getCoupon &&
      props.getCoupon.getCoupon &&
      props.getCoupon.getCoupon.length > 0
        ? props.getCoupon.getCoupon
        : [];
    let allInfluencerRes =
      allInfluencer && allInfluencer.length > 0 ? allInfluencer : [];
    let getCoupons = differenceBy(
      getCouponsRes,
      allInfluencerRes,
      "coupon_code"
    );

    return (
      <div className={classes.root}>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Add Influencer
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoFocus
                onChange={(e) => handleChange(e)}
              />

              <p style={{ color: "red" }}>{error.name}</p>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                onChange={(e) => handleChange(e)}
              />
              <p style={{ color: "red" }}>{error.email}</p>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="paypal_id"
                label="Paypal Id"
                name="paypal_id"
                autoComplete="paypalId"
                autoFocus
                onChange={(e) => handleChange(e)}
              />
              <p style={{ color: "red" }}>{error.paypal_id}</p>
              <Input
                type="number"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="commission"
                label="Commission"
                placeholder="Commission"
                name="commission"
                autoFocus
                onChange={(e) => handleChange(e)}
              />
              <p style={{ color: "red" }}>{error.commission}</p>
              <Autocomplete
                id="combo-box-demo"
                options={getCoupons}
                getOptionLabel={(option) => option.coupon_code}
                style={{ width: 400 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Coupon Code"
                    variant="outlined"
                  />
                )}
              />
              <br />
              <Autocomplete
                id="country-box-selection"
                options={countryOptions}
                style={{ width: 400 }}
                renderInput={(params) => (
                  <TextField {...params} label="Country" variant="outlined" />
                )}
              />
              <p style={{ color: "red" }}>{error.coupon_code}</p>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => submitHandler(e)}>
                Submit
              </Button>
            </form>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToprops = (dispatch) => {
  return {
    signup: (data) => {
      dispatch(signup(data));
    },
    getCoupons: () => {
      dispatch(getCoupons());
    },
  };
};

const mapStateToprops = (state) => ({
  getCoupon: state.getCoupon,
});

export default connect(mapStateToprops, mapDispatchToprops)(AddInfluencer);
