import React, { Component } from 'react'

export default class Header extends Component {
  render() {
    return (
      <div>
        
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">   
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">Create Coupon</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/add-influencer">Add Influencer</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/coupons">Coupons</a>
              </li>
              <li className="nav-item">
              <a className="nav-link" href="/influencer">Influencer</a>
            </li>
            </ul>
          </div>
          </div>
        </nav>
      
      </div>
    )
  }
}
