const skuDisplayNames = {
  stemwizpack: "Plugo STEM Wiz Pack",
  orboot_dinos: "Orboot Dinos",
  orboot: "Orboot Earth",
  space: "Shifu Space",
  safari: "Shifu Safari",
  travel: "Shifu Travel",
  mingling_monster: "Shifu Mingling Monster",
  mingling_animals: "Shifu Minling Animals",
  plugo_1kit: "Plugo 1 Kit",
  plugo_2kit: "Plugo 2 Kit",
  plugo_4kit: "Plugo 4 Kit",
  plugo_gamepad: "Plugo Gamepad",
  plugo_link: "Plugo Link",
  plugo_count: "Plugo Count",
  plugo_steer: "Plugo Steer",
  plugo_piano: "Plugo Tunes",
  plugo_quest: "Plugo Quest",
  plugo_slingshot: "Plugo Slingshot",
  plugo_letters: "Plugo Letters",
  orboot_travel_kit: "Shifu Orboot: Travel Kit",
  link_gamepad: "Plugo Link",
  count_gamepad: "Plugo Count",
  piano_gamepad: "Plugo Tunes",
  letters_gamepad: "Plugo Letters",
  link_count_combo: "Plugo STEM Pack - 2 Kits",
  makerspace_pack: "MakerSpace Pack",
  starter_pack: "Starter Pack",
  classroom_pack: "Classroom Pack",
};

export { skuDisplayNames };
