import React from "react";
import { useState, useEffect } from "react";
import { API_SERVICE, WEB_API, WEB_API_STAGE } from "../_actions/Type";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "../asstets/coupon.css";

import Loader from "../components/Loader";
import MultipalSelectOpetion from "../components/MultipalSelectOpetion";
import Button from "@material-ui/core/Button";
import $ from "jquery";

import { skuDisplayNames } from "../_constants/skus";
import { createCoupon } from "../_actions/CreateCouponAction";

import { handleCouponValidation } from "../_utils/form_validation";

import { countryCode, countryOptions } from "../_constants/countries";
import { TextField, Select, MenuItem,InputLabel } from "@material-ui/core";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "../App.css";
import {useParams} from "react-router-dom";


import Checkbox from "@material-ui/core/Checkbox";
// import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	header: {
		padding: "10px 16px",
		background: "#555",
		color: "#f1f1f1",
	},
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function CouponCreator(props) {
	const classes = useStyles();
	const [selectedFilm, setSelectedFilm] = useState([]);
	const [selectedCountries, setselectedCountries] = useState([]);
	const [env, setenv] = useState("stage");
	const [skus, setskus] = useState([]);
    // useEffect(()=>{
    //     console.log("selectedFilm",selectedCountries)
    // },[selectedFilm])

	const [selectedCountry, setSelectedCountry] = useState([]);
	const [selectedSkus, setSelectedSkus] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [createCouponRes, setCreateCouponRes] = useState({});

	const [formData, setFormData] = useState({
		env: "",
		discountType: "",
		coupon_for: "",
		selectCountry: "",
		couponCode: "",
		couponApply: "",
		// minimum_cart_value_influencer: "",
		// minimum_cart_value_web_coupons: "",
		// minimum_cart_value_inr: "",
		// minimum_cart_value_usd: "",
		coupon_use_time: false,
		expiration_obj: null,
		expiration: null,
	});
// useEffect(()=>{
//     console.log("HELLO",formData.selectCountry)
// },[formData.selectCountry])
	const [error, setError] = useState({
		env: "",
		discountType: "",
		coupon_for: "",
		selectCountry: "",
		couponCode: "",
		couponApply: "",
		selectSkus: "",
		// minimum_cart_value_influencer: "",
		// minimum_cart_value_web_coupons: "",
		// minimum_cart_value_inr: "",
		// minimum_cart_value_usd: "",
	});

	useEffect(() => {
		if (selectedFilm.indexOf("All") > -1) {
			setselectedCountries([...countryCode]);
		} else {
			setselectedCountries([...selectedFilm]);
		}
	}, [selectedFilm]);

	useEffect(() => {
		getSkus();
	}, [env]);

	const getSkus = () => {
		// let url = env == "stage" ? WEB_API_STAGE : WEB_API;
		let url = WEB_API_STAGE;

		fetch(`${url}/get_sku_names`, {
			method: "get",
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (dataRes) {
				console.log(dataRes);
				console.log(dataRes);
				setskus(dataRes);
			})
			.catch((error) => {
				console.log("error", JSON.stringify(error));
			});
	};
 
	const handleChange = (e) => {
		let name = e.target.name;
		let event_value = e.target.value;


        // console.log("NEWWW",name,event_value)

		setError({
			env: "",
			discountType: "",
			coupon_for: "",
			selectCountry: "",
			couponCode: "",
			couponApply: "",
			selectSkus: "",
			minimum_cart_value_influencer: "",
			minimum_cart_value_web_coupons: "",
			minimum_cart_value_inr: "",
			minimum_cart_value_usd: "",
		});

		if (name === "couponValue") {
			if (formData.discountType === "percentage" && event_value > 50) {
				document.getElementById("couponApplyErr").innerHTML = "percentage should be <= 50%";
			} else if (formData.discountType === "value" && event_value > 500) {
				document.getElementById("couponApplyErr").innerHTML = "value should be <= 500";
			} else {
				document.getElementById("couponApplyErr").innerHTML = "";
			}
		} else {
			document.getElementById("couponApplyErr").innerHTML = "";
		}

		if (name == "couponCode") {
			console.log("Setting code uppercase");
			event_value = event_value.toUpperCase();
		}

		setFormData((prevState) => ({ ...prevState, [name]: event_value }));
	};

	const countrySelectHandleChange = (event) => {
		setSelectedCountry(event.target.value);
	};

	const getCheckBox = () => {
		let selecdSku = [];
		$.each($("input[name='skus']:checked"), function () {
			selecdSku.push($(this).val());
		});
		setSelectedSkus(selecdSku.join(","));
		return selecdSku.join(",");
	};

    // let [loading,setLoading] = useState(false);
	const submitHandler = (e) => {
        // setLoading(true);
		// let selectSkus = getCheckBox();
    
		let selectSkus = couponSKUobj;

		let useCouponType = "";
		if ($("#useCouponType").prop("checked") === true) {
			useCouponType = "ONE-TIME";
		} else {
			useCouponType = "LIFE-TIME";
		}

		let selectedCountry = [...countryCode];

		if (selectedFilm.indexOf("All") > -1) {
			selectedCountry = [...countryCode];
		} else {
			selectedCountry = [...selectedFilm];
		}

		// console.log(formData.expiration);

		let inputObj = {
			...formData,
			coupon_use_time: useCouponType,
			selectSkus: formData.couponApply == "onSkus" ? selectSkus : "",
			selectCountry: `${[selectedCountry]}`,
			is_active: env == "stage" ? "1" : "0",
		};
		let inputError = handleCouponValidation(inputObj);

		const { errors } = inputError;

		if (!inputError.formIsValid) {
			setError({ ...errors });
			e.stopPropagation();
			return;
		}

		console.log("inputObj", inputObj);

		setIsLoading(true);

		// let url = env == "stage" ? WEB_API_STAGE : WEB_API;
		let url = WEB_API_STAGE;

		

		fetch(`${url}/updatecoupon`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(inputObj),
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (dataRes) {
				setIsLoading(false);
				if (dataRes.errorMsg) {
					alert(dataRes.msg);
					// window.location.reload();
				} else {
					alert("Created Updated Successfuly!");
					// window.location.reload();
				}
			})
			.catch((error) => {
				console.log("error", JSON.stringify(error));
				alert("Server Error");
				// window.location.reload();
				setIsLoading(false);
			});
	};


	let [couponSKUobj, setCouponSKUobj] = useState({});


	const handleChangeBottom = (obj) => {
        obj.e.preventDefault();
		let { sku, country, value, input } = obj;
        let copyObj = {...couponSKUobj};
        // const arr = arr.filter(item => item.key !== "some value");
        if(copyObj.all){
            delete copyObj.all;
        }
        console.log("copyObj",copyObj);
        let countryObj = {...copyObj[sku]}
        countryObj[country] = {...countryObj[country],[input]: value}
        copyObj[sku] = countryObj;
        setCouponSKUobj({...copyObj});

	};

const {coupon} = useParams();

const getSingleCoupon = () => {
    
    // let url = env == "stage" ? WEB_API_STAGE : WEB_API;
    let url = WEB_API_STAGE;
    fetch(`${url}/get_coupon_allcases?code=${coupon}`)
       .then((response) => response.json())
       .then((result) => {
        if(result.status === "success"){
            // setenv("prod");
            let {country,code_value,coupon_code,coupon_use_time,discountType,fallback_coupon,is_applicable,sku,expiration,coupon_terms,max_qty,max_unit_per_kit,maximum_cart_values,min_qty,min_unit_per_kit,minimum_cart_values,units_left,coupon_for,values,expiration_obj} = result.coupon;

            setFormData({
                env: env,
    discountType: discountType,
    coupon_for:  coupon_for,
    selectCountry: country,
    couponCode: coupon_code,
    couponApply: is_applicable,
    coupon_use_time: coupon_use_time,
    expiration_obj: expiration_obj,
    expiration: expiration,
    couponValue:code_value,
    units_left:units_left,
    fallback_coupon:fallback_coupon,
    min_unit_per_kit:min_unit_per_kit,
    max_unit_per_kit:max_unit_per_kit,
    maximum_cart_values:maximum_cart_values,
    minimum_cart_values:minimum_cart_values,
            });
            setSelectedFilm([...country.split(",")]);
            // values
            // selectSkus
let obj  = {

}
            let allValues = values;
            console.log("ALLVALUES",allValues)
            allValues.map(each=>{
                let inner = {
                    ...obj[each.sku],
                    [each.country]:{
                        discountValue:each.discount_value,
                        typeofDiscount:each.discount_type,
                    }
                }
                obj[each.sku] = inner;
            })
            // console.log("obj",obj)
            setCouponSKUobj({...obj})
        }         
       })
       .catch((error) => console.log("error", error));
   };
   useEffect(()=>{
    getSingleCoupon();
   },[])

	if (isLoading) {
		return <Loader />;
	} else {
		return (
			<section>
				<div className={classes.root}>
					<section className="mt-100">
						<Container>
							<div className="coupon-form">
								<div className="form">
                                <h3 style={{marginBottom:"60px"}}>Edit Coupon</h3>
									<div className="form-group">
                                      
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="coupondb">Code Environment: </label>
											</div>
                                            {formData.env === "stage" && (
											<div className="col-md-2">
												<label>
													<input
														type="radio"
														name="env"
														value="ecomm_dev"
														onChange={(e) => {
															setenv("stage");
															handleChange(e);
														}}
                                                        checked={formData.env === "stage"}
                                                        

													/>{" "}
													Stage
												</label>
											</div>
)}
                                            {formData.env === "prod" && (

											<div className="col-md-2">
												<label>
													<input
														type="radio"
														name="env"
														value="ecomm_prod"
														onChange={(e) => {
															setenv("prod");
															handleChange(e);
														}}
                                                        checked={formData.env === "prod"}

													/>{" "}
													Prod
												</label>
											</div>
)}

										</div>
									</div>

									<p style={{ color: "red" }}>{error.env}</p>

									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="coupon code">Coupon Code:</label>
											</div>
											<div className="col-md-3">
												<input
													type="text"
													className="form-control"
													placeholder="Enter Coupon Code"
													name="couponCode"
													onChange={(e) => handleChange(e)}
													value={formData.couponCode}
												/>
												<p style={{ color: "red" }}>{error.couponCode}</p>
											</div>

											<div className="col-md-2">
												<input
													type="number"
													className="form-control"
													placeholder="Enter Coupon Value"
													name="couponValue"
													min="0"
													maxLength={10}
													onChange={(e) => handleChange(e)}
                                                    value={formData.couponValue}
                                                    onWheel={ event => event.currentTarget.blur() }
												/>
												<p style={{ color: "red" }}>{error.couponValue}</p>
												<p id="couponApplyErr" style={{ color: "red" }}></p>
											</div>
										</div>
									</div>

									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="email">Discount Type: </label>
											</div>
											<div className="col-md-2">
												<label>
													<input
														type="radio"
														name="discountType"
														value="percentage"
														onChange={(e) => handleChange(e)}
                                                        checked={formData.discountType === "percentage"}
                                                        
													/>{" "}
													Percentage
												</label>
											</div>
											<div className="col-md-1">
												<label>
													<input
														type="radio"
														name="discountType"
														value="value"
														onChange={(e) => handleChange(e)}
                                                        checked={formData.discountType === "value"}


													/>{" "}
													Value
												</label>
											</div>
										</div>
									</div>
									<p style={{ color: "red" }}>{error.discountType}</p>

									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="email">Coupon Type: </label>
											</div>
											<div className="col-md-2">
												<label>
													<input
														type="radio"
														name="couponApply"
														value="onCart"
														onChange={(e) => handleChange(e)}
                                                        checked={formData.couponApply === "onCart"}

													/>{" "}
													Apply On Cart
												</label>
											</div>
											<div className="col-md-2">
												<label>
													<input
														type="radio"
														name="couponApply"
														value="onSkus"
														onChange={(e) => handleChange(e)}
                                                        
                                                        checked={formData.couponApply === "onSkus"}

													/>{" "}
													Apply On Skus
												</label>
											</div>
										</div>
									</div>
									<p style={{ color: "red" }}>{error.couponApply}</p>

									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="email">Use Coupon Type: </label>
											</div>
											<div className="col-md-4">
												<input
													type="checkbox"
													id="useCouponType"
													name="coupon_use_time"
													value="ONE-TIME"
                                                    checked={formData.coupon_use_time != "LIFE-TIME"}

                                                    
												/>{" "}
												Apply Coupon One Time
											</div>
										</div>
									</div>

									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="email">Coupon For: </label>
											</div>
											<div className="col-md-2">
												<label>
													<input
														type="radio"
														name="coupon_for"
														value="website"
														onChange={(e) => handleChange(e)}
                                                        checked={formData.coupon_for === "website"}
													/>{" "}
													Website
												</label>
											</div>
											<div className="col-md-2">
												<label>
													<input
														type="radio"
														name="coupon_for"
														value="influencer"
														onChange={(e) => handleChange(e)}
                                                        checked={formData.coupon_for === "influencer"}

													/>{" "}
													Influencer
												</label>
											</div>
										</div>
									</div>
									<p style={{ color: "red" }}>{error.couponFor}</p>

									{/* <div className="form-group">
										{selectedCountries.map((country) => (
											<div className="row">
												<div className="col-md-2">
													<label htmlFor="coupon code">
														Min Cart Value {country}:
													</label>
												</div>
												<div className="col-md-2">
													<input
														type="number"
														className="form-control"
														placeholder="Enter value"
														name={"mincartval_" + country}
														min={50}
														maxLength={200}
														onChange={(e) => handleChange(e)}
													/>
													<p style={{ color: "red" }}>
														{error.minimumCart}
													</p>
													<p
														id="minimumCartValue"
														style={{ color: "red" }}
													></p>
												</div>
											</div>
										))}
									</div> */}

									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="country">Country: </label>
											</div>

											<MultipalSelectOpetion
												selectedFilm={selectedFilm}
												setSelectedFilm={setSelectedFilm}
												countryCode={countryOptions}
											/>



										</div>
									</div>
									<p style={{ color: "red" }}>{error.selectCountry}</p>

									{selectedCountries.length > 0 && (
										<div className="form-group">
											<table>
												<thead>
													<th></th>
													<th className="text-center">Min Cart Value</th>
													<th className="text-center">Max Cart Value</th>
													<th className="text-center">
														Min Unit Per Kit
													</th>
													<th className="text-center">
														Max Unit Per Kit
													</th>
												</thead>
												<tbody>
													{selectedCountries.map((country) => (
														<tr>
															<th className="text-center">
																{country}
															</th>

															<td>
																<div className="">
																	<input
                                                    onWheel={ event => event.currentTarget.blur() }

																		type="number"
																		className="form-control"
																		placeholder="Enter value"
																		name={
                                                                            // `minimum_cart_values[country]`
																			"mincartval_" + country
																		}
																		min={50}
																		maxLength={200}
                                                                        
                                                                        value={formData.minimum_cart_values[country] < 0 ? "" : formData.minimum_cart_values[country]}
																		// onChange={(e) =>
																		// 	handleChange(e)
																		// }
                                                                        onChange={(e) =>{
                                                                            setFormData((prevState) => ({ ...prevState, minimum_cart_values: {...prevState.minimum_cart_values,[country]:e.target.value} }))
                                                                                                                                            }}
																	/>
																</div>
															</td>
															<td>
																<div className="">
																	<input
																		type="number"
																		className="form-control"
																		placeholder="Enter value"
																		name={
																			"maxcartval_" + country
																		}
																		min={50}
																		maxLength={200}
                                                                        value={formData.maximum_cart_values[country] < 0 ? "" : formData.maximum_cart_values[country]}
																		// onChange={(e) =>
																		// 	handleChange(e)
																		// }
                                                    onWheel={ event => event.currentTarget.blur() }

                                                                        onChange={(e) =>{
                                                                            setFormData((prevState) => ({ ...prevState, maximum_cart_values: {...prevState.maximum_cart_values,[country]:e.target.value} }))
                                                                                                                                            }}
																	/>
																</div>
															</td>
															<td>
																<div className="">
																	<input
																		type="number"
																		className="form-control"
																		placeholder="Enter value"
																		name={
																			"min_unit_per_kit_" +
																			country
																		}
																		min={50}
																		maxLength={200}
                                                                        value={formData.min_unit_per_kit[country] < 0 ? "" : formData.min_unit_per_kit[country]}
																		onChange={(e) =>{
		setFormData((prevState) => ({ ...prevState, min_unit_per_kit: {...prevState.min_unit_per_kit,[country]:e.target.value} }))
                                                                        }
                                                                            // {
                                                                            //     console.log("test",e.target.value);
                                                                            // }
																			// handleChange(e)
																		}
                                                    onWheel={ event => event.currentTarget.blur() }

																	/>
																</div>
															</td>
															<td>
																<div className="">
																	<input
																		type="number"
																		className="form-control"
																		placeholder="Enter value"
																		name={
																			"max_unit_per_kit_" +
																			country
																		}
																		min={50}
																		maxLength={200}
                                                                        value={formData.max_unit_per_kit[country] < 0 ? "" : formData.max_unit_per_kit[country]}
																		// onChange={(e) =>
																		// 	handleChange(e)
																		// }
                                                    onWheel={ event => event.currentTarget.blur() }

                                                                        onChange={(e) =>{
                                                                            setFormData((prevState) => ({ ...prevState, max_unit_per_kit: {...prevState.max_unit_per_kit,[country]:e.target.value} }))
                                                                                                                                            }}
																	/>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									)}

									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="units_left">Max total usage:</label>
											</div>
											<div className="col-md-3">
												<input
													type="number"
													className="form-control"
													placeholder="Enter value"
													name="units_left"
													min={50}
													maxLength={200}
                                                    value={formData.units_left}
													onChange={(e) => handleChange(e)}
                                                    onWheel={ event => event.currentTarget.blur() }

												/>
												<p style={{ color: "red" }}>{error.totalUsage}</p>
											</div>
										</div>
									</div>
									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="units_left">End Date & Time:</label>
											</div>
											<div className="col-md-6">
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DateTimePicker
														renderInput={(props) => (
															<TextField {...props} />
														)}
														// label="DateTimePicker"
														value={formData.expiration}
														onChange={(e) => {
															setFormData((prevState) => ({
																...prevState,
																expiration_obj: e,
																expiration: moment(e["$d"]).format(
																	"YYYY-MM-DD HH:mm:ss"
																),
															}));
														}}
														inputFormat="YYYY-MM-DD HH:mm:ss"
														disablePast
													/>
												</LocalizationProvider>
												<p style={{ color: "red" }}>{error.totalUsage}</p>
											</div>
										</div>
									</div>

									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="fallback_coupon">
													Fallback Coupon Code:
												</label>
											</div>
											<div className="col-md-3">
												<input
													type="text"
													className="form-control"
													placeholder="Enter code"
													name="fallback_coupon"
                                                    value={formData.fallback_coupon}
													onChange={(e) => handleChange(e)}
												/>
												<p style={{ color: "red" }}>{error.couponCode}</p>
											</div>
										</div>
									</div>

									<div className="form-group">
										<div className="row">
											<div className="col-md-2">
												<label htmlFor="email">Select Sku: </label>
											</div>
											<div className="col-md-10">
												{formData.couponApply === "onCart" ? (
													<button
														type="button"
														className="btn btn-primary"
														disabled
													>
														Select Sku
													</button>
												) : (
													<button
														type="button"
														className="btn btn-primary"
														data-toggle="collapse"
														data-target="#demo"
													>
														Select Sku
													</button>
												)}
												<p style={{ color: "red" }}>{error.couponSkus}</p>
												{/* <div id="demo" className="collapse mt-4 "> */}

												{/* </div> */}
											</div>
										</div>
										<div id="demo" className="skuOptions collapse">
										<div className="skuOptions">
											{/* <div className="form-group">
												<div className="row">
													<div className="col-md-2">
														<label htmlFor="country">Country: </label>
													</div>

													<MultipalSelectOpetion
														selectedFilm={selectedOptionsSKU}
														setSelectedFilm={setselectedOptionsSKU}
														countryCode={countryOptions}
													/>
												</div>
											</div>
											<p style={{ color: "red" }}>{error.selectCountry}</p> */}

											{selectedCountries.length > 0 ? (
												<div className="formParent">
													<table>
														<thead>
															<th className="text-center">SKU</th>

															{selectedCountries.map((each) => (
																<th className="text-center">
																	{each}
																</th>
															))}
														</thead>
														<tbody>
															{skus.map((sku) => (
																<tr>
																	<th>{sku.display_name}</th>

																	{selectedCountries.map(
																		(country) => (
																			<td>
																				<div className="twoInputs">
                                                                         
																					<Select
																						labelId="demo-simple-select-label"
																						id="demo-simple-select"
																						
																						value={
																							(couponSKUobj?.[
																								sku.id
																							]?.[
																								country
																							]?.typeofDiscount) ?  (couponSKUobj[
																								sku.id
																							][
																								country
																							].typeofDiscount) : "select"}

																								
																						onChange={(
																							e
																						) =>handleChangeBottom(
																								{e,
																									sku: sku.id,
																									country,
																									value: e
																										.target
																										.value,
																									input: "typeofDiscount",
																								}
																							)
                                                                                        
																						}
                                                                                        
																					>
                                                                                        <MenuItem disabled value="select">select</MenuItem>
            
          

																						<MenuItem
																							value={
																								"percentage"
																							}
																						>
																							Percentage
																						</MenuItem>
																						<MenuItem
																							value={
																								"value"
																							}
																						>
																							Value
																						</MenuItem>
																					</Select>
																					<input
																						type="number"
																						className="form-control"
																						placeholder="Enter value"
																						
																						min={50}
																						maxLength={
																							200
																						}
																						value={
																							(couponSKUobj?.[ 
																								sku.id
																							]?.[
																								country
																							]?.discountValue) ?  (couponSKUobj[
																								sku.id
																							][
																								country
																							].discountValue) : ""}
																						onChange={(
																							e
																						) => handleChangeBottom(
																								{e,
																									sku: sku.id,
																									country,
																									value: e
																										.target
																										.value,
																									input: "discountValue",
																								}
																							)
																						}
                                                    onWheel={ event => event.currentTarget.blur() }

																					/>
																				</div>
																			</td>
																		)
																	)}
																</tr>
															))}
														</tbody>
													</table>
												</div>
											): <p style={{ color: "red",marginLeft:"100px" }}>Please select Country</p>}
										</div>
                                        </div>
									</div>

									<div className="col-md-3">
										<Button
											type="button"
											fullWidth
											variant="contained"
											color="primary"
											className={classes.submit}
											onClick={(e) => submitHandler(e)}
										>
											Update
										</Button>
									</div>
								</div>
							</div>
						</Container>
					</section>
				</div>
			</section>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		createCoupon: (data) => {
			dispatch(createCoupon(data));
		},
	};
};

const mapStateToProps = (state) => ({
	createCouponRes: state.createCouponRes,
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponCreator);
