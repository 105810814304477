const influencerValidation = (fields) => {
	let errors = {};
	let formIsValid = true;

	if (!fields["name"]) {
		formIsValid = false;
		errors["name"] = "*Please Enter Your Name.";
	}

	if (!fields["email"]) {
		formIsValid = false;
		errors["email"] = "Cannot be empty";
	}

	if (typeof fields["email"] !== "undefined") {
		let lastAtPos = fields["email"].lastIndexOf("@");
		let lastDotPos = fields["email"].lastIndexOf(".");

		if (
			!(
				lastAtPos < lastDotPos &&
				lastAtPos > 0 &&
				fields["email"].indexOf("@@") == -1 &&
				lastDotPos > 2 &&
				fields["email"].length - lastDotPos > 2
			)
		) {
			formIsValid = false;
			errors["email"] = "*Email is not valid";
		}
	}

	if (!fields["paypalId"]) {
		formIsValid = false;
		errors["paypalId"] = "*Please Enter paypal_id.";
	}
	// if (typeof fields["paypalId"] !== "undefined") {
	//   let lastAtPos = fields["paypalId"].lastIndexOf("@");
	//   let lastDotPos = fields["paypalId"].lastIndexOf(".");

	//   // if (
	//   //   !(
	//   //     lastAtPos < lastDotPos &&
	//   //     lastAtPos > 0 &&
	//   //     fields["paypalId"].indexOf("@@") == -1 &&
	//   //     lastDotPos > 2 &&
	//   //     fields["paypalId"].length - lastDotPos > 2
	//   //   )
	//   // ) {
	//   formIsValid = false;
	//   errors["paypalId"] = "*Paypal Email cannot be empty";
	//   // }
	// }

	if (!fields["couponCode"]) {
		formIsValid = false;
		errors["coupon_code"] = "*Please Enter coupon_code.";
	}

	return { formIsValid, errors };
};

const handleAddInfluencerValidation = (fields) => {
	let errors = {};
	let formIsValid = true;

	if (!fields["name"]) {
		formIsValid = false;
		errors["name"] = "*Please Enter Your Name.";
	}

	if (!fields["email"]) {
		formIsValid = false;
		errors["email"] = "Cannot be empty";
	}

	if (!fields["commission"]) {
		formIsValid = false;
		errors["commission"] = "*Please commission percentage.";
	}

	if (!fields["email"]) {
		formIsValid = false;
		errors["email"] = "Cannot be empty";
	}

	if (typeof fields["email"] !== "undefined") {
		let lastAtPos = fields["email"].lastIndexOf("@");
		let lastDotPos = fields["email"].lastIndexOf(".");

		if (
			!(
				lastAtPos < lastDotPos &&
				lastAtPos > 0 &&
				fields["email"].indexOf("@@") == -1 &&
				lastDotPos > 2 &&
				fields["email"].length - lastDotPos > 2
			)
		) {
			formIsValid = false;
			errors["email"] = "*Email is not valid";
		}
	}

	if (!fields["paypal_id"]) {
		formIsValid = false;
		errors["paypal_id"] = "*Please Enter paypal_id.";
	}

	// if (typeof fields["paypal_id"] !== "undefined") {
	//   let lastAtPos = fields["paypal_id"].lastIndexOf("@");
	//   let lastDotPos = fields["paypal_id"].lastIndexOf(".");

	//   if (
	//     !(
	//       lastAtPos < lastDotPos &&
	//       lastAtPos > 0 &&
	//       fields["paypal_id"].indexOf("@@") == -1 &&
	//       lastDotPos > 2 &&
	//       fields["paypal_id"].length - lastDotPos > 2
	//     )
	//   ) {
	//     formIsValid = false;
	//     errors["paypal_id"] = "*Paypal Email is not valid";
	//   }
	// }

	//this is for influencer page
	if (!fields["paypal_id"]) {
		formIsValid = false;
		errors["paypal_id"] = "*Please Enter paypal_id.";
	}
	if (typeof fields["paypal_id"] === "undefined") {
		let lastAtPos = fields["paypal_id"].lastIndexOf("@");
		let lastDotPos = fields["paypal_id"].lastIndexOf(".");

		// if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["paypal_id"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["paypal_id"].length - lastDotPos) > 2)) {
		formIsValid = false;
		errors["paypal_id"] = "*Paypal id cannot be empty";
		// }
	}

	//this is for influencer page
	if (!fields["coupon_code"]) {
		formIsValid = false;
		errors["coupon_code"] = "*Please Enter couponCode.";
	}

	if (!fields["coupon_code"]) {
		formIsValid = false;
		errors["coupon_code"] = "*Please Enter couponCode.";
	}

	return { formIsValid, errors };
};

const handleCouponValidation = (fields) => {
	console.log("handleCouponValidation", fields);
	let errors = {};
	let formIsValid = true;

	if (!fields["env"]) {
		formIsValid = false;
		errors["env"] = "*Please select env.";
	}

	if (!fields["discountType"]) {
		formIsValid = false;
		errors["discountType"] = "*Please select discount type.";
	}

	if (!fields["selectCountry"]) {
		formIsValid = false;
		errors["selectCountry"] = "*Please select country.";
	}

	if (!fields["couponCode"]) {
		formIsValid = false;
		errors["couponCode"] = "*Please select coupon Code.";
	}

	if (fields["couponCode"].length < 3) {
		formIsValid = false;
		errors["couponCode"] = "*Please Enter minimum 3 Character.";
	}

	if (typeof fields["couponCode"] !== "undefined") {
		if (!fields["couponCode"].match(/^[0-9a-zA-Z]+$/)) {
			formIsValid = false;
			errors["couponCode"] = "*Please input alphanumeric characters only";
		}
	}

	if (!fields["couponApply"]) {
		formIsValid = false;
		errors["couponApply"] = "*Please select coupon apply.";
	} else if (fields["couponApply"] == "onSkus" && fields["selectSkus"].length == 0) {
		formIsValid = false;
		errors["couponSkus"] = "*Please select skus.";
	}

	if (!fields["coupon_for"]) {
		formIsValid = false;
		errors["couponFor"] = "*Please select coupon type.";
	}

	if (!fields["couponCode"]) {
		formIsValid = false;
		errors["couponCode"] = "*Please select coupon code.";
	}

	if (!fields["couponValue"]) {
		formIsValid = false;
		errors["couponValue"] = "*Please select coupon code.";
	}

	//this.setState({errors: errors});
	return { formIsValid, errors };
};

export { influencerValidation, handleCouponValidation, handleAddInfluencerValidation };
