import { API_SERVICE, SIGNUP } from './Type'

const signup = (data) => (dispatch) => {

  fetch(`${API_SERVICE}/inluencer-signup`, {
    method: 'post',
    body: JSON.stringify(data)
  }).then(function(response) {
    return response.json();
  }).then(function(dataRes) {
    return dispatch({
      type: SIGNUP,
      payload: dataRes
    });
  }).catch((error) => console.log("error", JSON.stringify(error)));;
};

export { signup }