import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
 const { selectedFilm,  setSelectedFilm} = props

  return (
    <Autocomplete
      multiple
      id="select-country"
      options={props.countryCode}
      disableCloseOnSelect
      onChange={(e, film) => {setSelectedFilm(film)}}
      getOptionLabel={option => option}
      value={selectedFilm}
      renderOption={(option, state) => {
        const selectFilmIndex = selectedFilm.findIndex(film => film.toLowerCase() === "all");
        if (selectFilmIndex > -1) {
          state.selected = true;
        }
        else{
            if(selectedFilm.includes(option)){ state.selected = true}
        }
        
        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={state.selected}
            />
            {option}
          </React.Fragment>
        );
      }}
      style={{ width: 500 }}
      renderInput={params => {
        console.log("params",params)
        return(
        <TextField
          {...params}
          variant="outlined"
          label="Country"
          placeholder="Country Code"
        />
      )}}
    />
  );
}
