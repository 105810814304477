
import { CREATE_COUPON } from '../_actions/Type';

const initialState = {loading:false, createCouponRes: {}}; 
function createCouponReducer(state = initialState, action) {
  
  switch (action.type) {
    case CREATE_COUPON:
      return {
        ...state,
        createCouponRes: action.payload
      };
    default:
      return state;
  }
}

export default createCouponReducer;