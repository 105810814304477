const API_SERVICE = "https://6jukvmvvul.execute-api.ap-south-1.amazonaws.com/stage";
const WEB_API = "https://prodserver.playshifu.com";
// const WEB_API = "http://localhost:5001";
const WEB_API_STAGE = "https://wwwserver.playshifu.com";
// const WEB_API_STAGE = "http://localhost:5001";
const GET_COUPON = "GET_COUPON";
const CREATE_COUPON = "CREATE_COUPON";
const SIGNUP = "SIGNUP";
const UPDATE_COUPON_STATUS = "UPDATE_COUPON_STATUS";
const GET_INFLUENCER = "GET_INFLUENCER";
const LOGIN = "LOGIN";

export {
	GET_INFLUENCER,
	UPDATE_COUPON_STATUS,
	API_SERVICE,
	CREATE_COUPON,
	GET_COUPON,
	SIGNUP,
	WEB_API,
	WEB_API_STAGE,
	LOGIN,
};
