import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "bootstrap/dist/js/bootstrap.bundle.min";

import ReactDOM from "react-dom";

import App from "./_Navigations";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";

import { store, persistor } from "../src/_store";
import { PersistGate } from "redux-persist/integration/react";

const rootElement = document.getElementById("root");
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	rootElement
);

serviceWorker.unregister();
